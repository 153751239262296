.root {
	border-radius: 50px;
	border: 1px solid;
	border-color: var(--color-btn-primary);
	cursor: pointer;
	background: transparent;
	font-family: var(--font-primary);
	box-sizing: border-box;
	white-space: nowrap;
	font-weight: 600;
	height: fit-content;
	user-select: none;
	
	&[variant="primary"] {
		color: var(--color-btn-txt-primary);
		background-color: var(--color-btn-primary);
		&:hover {
			background-color: var(--color-btn-primary-hover);
		}
		&:active {
			background-color: var(--color-btn-primary-pressed);
		}
		&:disabled {
			border-color:  var(--color-btn-primary-disabled);
			color: var(--color-btn-txt-primary-disabled);
			background-color: var(--color-btn-primary-disabled);
			pointer-events: none;
		}
	}
	&[variant="ghost"] {
		border-color:  var(--color-btn-primary);
		color: var(--color-btn-txt-ghost);
		background: var(--color-btn-ghost);
		&:hover {
			background-color: var(--color-btn-ghost-hover);
		}
		&:active {
			background-color: var(--color-btn-ghost-pressed);
		}
		&:disabled {
			border: 1px solid var(--color-btn-primary-disabled);
			color: var(--color-btn-txt-ghost-disabled);
			background-color: var(--color-btn-ghost-disabled);
			pointer-events: none;
		}
	}

	&[sizing="s"] {
		font-size: 12px;
		line-height: 18px;

		padding: 6px 12px;
	}

	&[sizing="m"] {
		font-size: 14px;
		line-height: 17px;

		padding: 8px 18px;
	}

	&[sizing="l"] {
		font-size: 18px;
		line-height: 22px;

		padding: 9px 20px;
	}

	&[sizing="xl"] {
		font-size: 24px;
		line-height: 28px;

		padding: 12px 32px;
	}
}
