:root {
	--content-max-width: 1440px;
	--template-margin: auto;
	--content-padding-left: 48px;
	--content-padding-right: 48px;
	--content-padding-bottom: 32px;
	--font-primary: "Source Sans Pro", sans-serif;
	--font-secondary: "Source Sans Pro", sans-serif;
}


[version="v0"][route="/phases"], [version="v0"][route="/sale-summary"], [version="v1"][route="/sale-summary"]  {
	--content-max-width: 1520px;
	--template-margin: 0;
	--content-padding-left: 0;
	--content-padding-bottom: 0;
	--content-padding-right: 0;
}
