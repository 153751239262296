@import "Themes/constants.scss";

.root {
	font-family: var(--font-primary);
	font-style: normal;
	font-weight: 700;
	font-size: 48px;
	line-height: 56px;
	/* identical to box height, or 117% */

	letter-spacing: -0.04em;

	color: var(--color-text);

	@media (max-width: $screen-m) {
		font-size: 35px;
		line-height: 48px;
	}
}
