@import "../Base/classes.module.scss";
@import "Themes/constants.scss";

.first-line {
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
}

.pancake-button {
	&.mobile {
        display: flex;
        justify-content: center;
        margin-top: 24px;
    }

    @media screen and (max-width: $screen-m) {
		&.desktop {
			display: none;
		}
	}

	@media screen and (min-width: $screen-m) {
		&.mobile {
			display: none;
		}
	}
}
.cards-container {
	margin-top: 32px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	gap: 32px;
}

.cards-half {
	display: flex;
	gap: 32px;

	@media screen and (max-width: $screen-m) {
		flex-direction: column-reverse;
	}
}

.button-harvest {
	display: flex;
	justify-content: center;
	margin-top: 16px;
}
