[theme-mode] {
	--color-primary: #4535ff;
	--color-secondary: #becbdb;
	--color-background: #f6f6f6;
	--color-background-transparency: #f6f6f6a0;
	--color-window: #ffffff;

	--color-input: #ffffff;
	--color-input-placeholder: #becbdb;

	--color-text: #151516;
	--color-paragraph: #5d6781;
	--color-warning: #dc2626;

	--color-btn-primary: #6254ff;
	--color-btn-primary-hover: #5344ff;
	--color-btn-primary-pressed: #4535ff;
	--color-btn-primary-disabled: #becbdb;

	--color-btn-ghost: none;
	--color-btn-ghost-hover: rgba(83, 68, 255, 0.05);
	--color-btn-ghost-pressed: rgba(69, 53, 255, 0.1);
	--color-btn-ghost-disabled: none;

	--color-btn-txt-square: #5d6781;
	--color-btn-square: #e7ecf2;
	--color-btn-square-hover: #dbe3ec;
	--color-btn-square-pressed: #becbdb;

	--color-btn-txt-primary: #ffffff;
	--color-btn-txt-primary-disabled: #ffffff;
	--color-btn-txt-ghost: #6254ff;
	--color-btn-txt-ghost-disabled: #becbdb;

	--color-table-row: rgba(69, 53, 255, 0.1);

	--linear-gradient-progress-bar: linear-gradient(90deg, #4535ff 0%, #7900f2 100%);
	--linear-gradient-progress-bar-completed: linear-gradient(
		90deg,
		rgba(69, 53, 255, 0.5) 0%,
		rgba(121, 0, 242, 0.5) 100%
	);
}

[theme-mode="dark"] {
	--color-primary: #35e0ff;
	--color-secondary: #3d406d;
	--color-background: #141c3d;
	--color-background-transparency: #141c3da0;
	--color-window: #262e52;

	--color-input: #12172f;
	--color-input-placeholder: #5d6781;

	--color-text: #ffffff;
	--color-paragraph: #c0c2d1;
	--color-warning: #f87171;

	--color-btn-primary: #35e0ff;
	--color-btn-primary-hover: #59e6ff;
	--color-btn-primary-pressed: #30d7f5;
	--color-btn-primary-disabled: #3d406d;

	--color-btn-ghost: none;
	--color-btn-ghost-hover: rgba(83, 68, 255, 0.05);
	--color-btn-ghost-pressed: rgba(69, 53, 255, 0.1);
	--color-btn-ghost-disabled: none;

	--color-btn-txt-primary: #141c3d;
	--color-btn-txt-primary-disabled: #ffffff;
	--color-btn-txt-ghost: #35e0ff;
	--color-btn-txt-ghost-disabled: #3d406d;

	--color-btn-txt-square: #ffffff;
	--color-btn-square: #12172f;
	--color-btn-square-hover: #171c32;
	--color-btn-square-pressed: #11162e;

	--color-table-row: rgba(53, 224, 255, 0.1);

	--linear-gradient-progress-bar: linear-gradient(90deg, #4535ff 0%, #7900f2 100%);
	--linear-gradient-progress-bar-completed: linear-gradient(
		90deg,
		rgba(69, 53, 255, 0.5) 0%,
		rgba(121, 0, 242, 0.5) 100%
	);
}

