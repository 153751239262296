@import "../Base/classes.module.scss";

.kyc-button-container {
	margin-top: 32px;
	display: flex;
	justify-content: center;
}

.title-subtext {
	font-family: var(--font-primary);
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;

	color: var(--color-paragraph);
}
