@import "Themes/constants.scss";
@import "Themes/fonts.scss";
@import "Themes/modes.scss";
@import "Themes/variables.scss";

body {
	margin: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: var(--color-background);
	font-family: var(--font-primary);
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

[route="/"] {
	background-image: url('/assets/images/pages/home/background.svg');
	background-position: left -205px bottom -42px;
	background-repeat: no-repeat;
	font-size: 0;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

[theme-mode="dark"][route="/"] {
	background-image: url('/assets/images/pages/home/background-dark.svg');
	background-position: left -205px bottom -42px;
	background-repeat: no-repeat;
}

[route="/phases"] {
	background-image: url('/assets/images/pages/phases/background.svg');
	background-repeat: no-repeat;
	background-position: center;
	background-position-y: 200px;
}

[route="/sale-summary"] {
	background-image: url('/assets/images/pages/saleSummary/background.svg');
	background-position: right -205px bottom -152px;
	background-repeat: no-repeat;
}

[theme-mode="dark"][route="/sale-summary"] {
	background-image: url('/assets/images/pages/saleSummary/background-dark.svg');
	background-position: right -205px bottom -152px;
	background-repeat: no-repeat;
}

[version="v0"][route="/phases"], [version="v0"][route="/sale-summary"], [version="v1"][route="/sale-summary"] {
	background-image: url('/assets/images/comingSoon/light.svg');
	background-position: bottom right;
	@media screen and (max-width: $screen-m) {
		background: linear-gradient(180deg, #4535ff 0, #7900f2 80px, rgba(0,0,0,0) 12%), var(--color-background);
	}
}

[version="v0"][theme-mode="dark"][route="/phases"], [version="v0"][theme-mode="dark"][route="/sale-summary"], [version="v1"][theme-mode="dark"][route="/sale-summary"] {
	background-image: url('/assets/images/comingSoon/dark.svg');
	background-position: bottom right;
	@media screen and (max-width: $screen-m) {
		background: linear-gradient(180deg, #4535ff 0, #7900f2 80px, rgba(0,0,0,0) 80px), var(--color-background);
	}
}


// [version="v0"][route="/sale-summary"] {
// 	background-image: none;
// 	@media screen and (max-width: $screen-m) {
// 		background-image: var(--color-background);
// 	}
// }

// [version="v0"][route="/sale-summary"] {
// 	background-image: url('/assets/images/comingSoon/light.svg');
// 	background-position: bottom right;
// 	@media screen and (max-width: $screen-m) {
// 		background: linear-gradient(180deg, #4535ff 0, #7900f2 80px, rgba(0,0,0,0) 12%), var(--color-background);
// 	}
// }

// [version="v0"][theme-mode="dark"][route="/sale-summary"] {
// 	background-image: url('/assets/images/comingSoon/dark.svg');
// 	background-position: bottom right;
// 	@media screen and (max-width: $screen-m) {
// 		background: linear-gradient(180deg, #4535ff 0, #7900f2 80px, rgba(0,0,0,0) 12%), var(--color-background);
// 	}
// }

// [version="v1"][route="/sale-summary"] {
// 	background-image: url('/assets/images/comingSoon/light.svg');
// 	background-position: bottom right;
// 	@media screen and (max-width: $screen-m) {
// 		background: linear-gradient(180deg, #4535ff 0, #7900f2 80px, rgba(0,0,0,0) 12%), var(--color-background);
// 	}
// }

// [version="v1"][theme-mode="dark"][route="/sale-summary"] {
// 	background-image: url('/assets/images/comingSoon/dark.svg');
// 	background-position: bottom right;
// 	@media screen and (max-width: $screen-m) {
// 		background: linear-gradient(180deg, #4535ff 0, #7900f2 80px, rgba(0,0,0,0) 12%), var(--color-background);
// 	}
// }