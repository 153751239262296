@import "Themes/constants.scss";

.root {
	max-width: var(--content-max-width);
	margin: var(--template-margin);
}


.content {
	padding-left: var(--content-padding-left);
	padding-right: var(--content-padding-right);
	padding-bottom: var(--content-padding-bottom);

	@media screen and (max-width: $screen-s) {
		padding-left: 16px;
		padding-right: 16px;
	}
}
