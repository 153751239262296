.root {
	display: flex;
	overflow-x: auto;
	gap: 0;
	justify-content: center;
	button {
		border-radius: 0;
		border-color: var(--color-background) !important;
		&[variant="ghost"] {
			color: var(--color-input);
			background-color: var(--color-secondary);
		}

		&:first-child {
			border-radius: 50px 0 0 50px;
			// &[variant="ghost"] {
			// 	border-left: 1px solid;
			// }
		}

		&:last-child {
			border-radius: 0px 50px 50px 0px;
			// &[variant="ghost"] {
			// 	border-right: 1px solid;
			// }
		}
	}
}
