.tooltip-content {
	font-family: var(--font-primary);
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 18px;
	color: var(--color-paragraph);
	margin-top: 8px;
	white-space: normal;
}

.tooltip-subtitle {
	font-weight: 700;
	color: var(--color-primary);
}