.crumb{
    width: 12px;
    height: 12px;
    background-color: var(--color-secondary);
    border-radius: 50px;

    &.active{
        background-color: var(--color-primary);
    }
    &.clickable{
        cursor: pointer;
    }
}