@import "Themes/constants.scss";

.root {
	display: flex;
	min-height: calc(100vh - 80px);

	@media screen and (max-width: $screen-m) {
		min-height: 0;
		flex-wrap: wrap;
		margin-top: 0;
		margin-right: 0;
		margin-left: 0;
		justify-content: center;
	}
}

.left-part {
	background: linear-gradient(106.18deg, #4535ff 0%, #7900f2 100%);
	min-width: 600px;
	@media screen and (max-width: $screen-m) {
		min-width: 0;
	}

	@media screen and (max-width: $screen-m) {
		margin-bottom: 50px;
		border-radius: 0px 0px 128px 128px;
	}

	@media screen and (max-width: $screen-s) {
		margin-left: -16px;
		margin-right: -16px;
	}
}

.computer-image-desktop {
	user-select: none;
	object-fit: none;
	position: absolute;
	top: 50%;
	transform: translate(0, -50%);
	@media screen and (max-width: $screen-m) {
		display: none;
	}
}

.big-ellipse {
	user-select: none;
	position: absolute;
	top: 25%;
	@media screen and (max-width: $screen-m) {
		display: none;
	}
}

.small-ellipse {
	user-select: none;
	position: absolute;
	bottom: 0;
	left: 278px;
	@media screen and (max-width: $screen-m) {
		display: none;
	}
}

.grid {
	user-select: none;
	position: absolute;
	bottom: 15%;
	@media screen and (max-width: $screen-m) {
		display: none;
	}
}

.computer-image-mobile {
	user-select: none;
	@media screen and (min-width: $screen-m) {
		display: none;
	}
	width: 100%;
	margin-top: 10px;
	margin-bottom: -30px;
}

.right-part {
	display: flex;
	flex-direction: column;
  text-align: justify;
	font-family: "Source Sans Pro";
	font-style: normal;

	@media screen and (max-width: $screen-m) {
		align-items: center;
	}

	@media screen and (min-width: $screen-m) {
		padding-top: 14vh;
		padding-left: 63px;
	}

	@media screen and (min-width: $screen-s) {
		padding-right: 48px;
		padding-left: 48px;
	}

	@media screen and (min-width: $screen-xl) {
		padding-top: 20vh;
	}
}

.title {
	font-weight: 700;
	font-size: 25px;
	color: var(--color-text);
}

.title2 {
	font-weight: 700;
	font-size: 40px;
	color: var(--color-primary);
	margin-bottom: 20px;
}

.text {
	font-weight: 400;
	font-size: 18px;
	color: var(--color-paragraph);
	@media screen and (max-width: $screen-m) {
		text-align: center;
	}
	ul{
		@media screen and (max-width: $screen-m) {
			text-align: left;
			padding-left: 20px;
		}
	}
}

