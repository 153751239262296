.root {
	min-width: 100px;
	max-width: 400px;
	background: var(--color-window);
	border-radius: 5px !important;
	position: fixed;
	transition: opacity 0.2s ease-in-out;
	text-align: left;
	filter: drop-shadow(0px 9px 18px rgba(0, 0, 0, 0.15));
	opacity: 0;
	pointer-events: none;
}

.root-content {
	padding: 16px 24px;
}

.show {
	opacity: 1;
}

.title {
	font-family: var(--font-primary);
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	line-height: 22px;
	color: var(--color-text);
}

.carrot {
	position: absolute;
	right: 20px;
	fill: var(--color-window);
}
