@import "Themes/constants.scss";

.root {
	max-width: 628px;
	margin: auto;
	padding: 32px;

	font-family: var(--font-primary);
	font-style: normal;

	display: flex;
	flex-direction: column;
	align-items: center;

	background-color: var(--color-window);
	box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.2);
	border-radius: 20px;

	@media screen and (max-width: $screen-s) {
		padding: 24px;
	}
}

.phase-title {
	font-weight: 600;
	font-size: 14px;
	text-transform: uppercase;
	color: var(--color-text);
}

.phase-name {
	font-weight: 700;
	font-size: 35px;
	color: var(--color-primary);
}

.type-fundraising {
	font-weight: 400;
	font-size: 20px;
	color: var(--color-paragraph);
	margin-bottom: 36px;
}

.price-range {
	display: flex;
	align-self: stretch;
	justify-content: space-between;

	font-weight: 400;
	font-size: 18px;
	color: var(--color-text);
	margin-bottom: 4px;
}

.token-symbol {
	margin-left: 4px;
	margin-right: 4px;
}

.progressbar {
	align-self: stretch;
	margin-bottom: 32px;
}

.infos {
	display: flex;
	flex-direction: column;
	align-self: stretch;
	margin-bottom: 12px;
	padding-bottom: 12px;
	border-bottom: 1px solid #3d406d;
}

.contract-info {
	display: flex;
	justify-content: space-between;
	padding-top: 8px;
	padding-bottom: 8px;
}

.info-title {
	font-weight: 600;
	font-size: 16px;
	color: var(--color-text);
	white-space: nowrap;
	padding-right: 16px;
}

.info-value {
	font-weight: 400;
	font-size: 16px;
	color: var(--color-paragraph);
	display: flex;
	align-items: center;
}

.whitelisted-message {
	font-weight: 700;
	font-size: 20px;
	color: var(--color-primary);
	margin-bottom: 32px;
}

.warning {
	color: var(--color-warning);
}

.amount-section {
	display: flex;
	flex-direction: column;
	align-self: stretch;
	margin-bottom: 32px;
}

.amount-title {
	font-weight: 400;
	font-size: 16px;
	color: var(--color-text);
	align-self: flex-start;
}

.amount-container {
	display: flex;
	margin-top: 4px;
	margin-bottom: 8px;
	.amount-input:invalid {
		border-color: rgba(255,0,0,1);
	}
	
	.amount-input:invalid+.max-input {
		border-color: rgba(255,0,0,1);
		pointer-events: none;
		opacity: 0.8;
	}
}

.amount-input {
	flex: 1;
	height: 41px;

	background: var(--color-input);
	border: 1px solid var(--color-secondary);
	box-sizing: border-box;
	border-radius: 3px;
	margin-right: 8px;
	color: var(--color-text);
	padding: 0 10px;
}


.amount-input::placeholder {
	font-weight: 400;
	font-size: 14px;
	color: var(--color-input-placeholder);
}

.amount-input::-webkit-outer-spin-button,
.amount-input::-webkit-inner-spin-button{
	-webkit-appearance: none;
	margin: 0
}

input[type=number] {
    -moz-appearance:textfield;
}

.balance-container {
	font-weight: 600;
	font-size: 16px;
	color: var(--color-text);
	align-self: flex-end;
}

.button-kyc {
	display: flex;
	justify-content: center;
	margin-top: 62px;
}
