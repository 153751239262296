.root {
	border-radius: 3px;;
	cursor: pointer;
	background: transparent;
	box-sizing: border-box;
	white-space: nowrap;
	height: 41px;
	
	font-family: var(--font-primary);
	font-weight: 400;
	font-size: 14px;
	line-height: 17px;
	padding: 12px 24px;

	color: var(--color-btn-txt-square);
	background-color: var(--color-btn-square);
	border: 1px solid var(--color-btn-square);
	&:hover {
		background-color: var(--color-btn-square-hover);
		border: 1px solid var(--color-btn-square-hover);
	}
	&:active {
		background-color: var(--color-btn-square-pressed);
		border: 1px solid var(--color-btn-square-pressed);
	}
}
