@import "Themes/constants.scss";

.root-mobile{
    @media(min-width: $screen-m){
        display: none;
    }
}

.root-desktop{
    @media(max-width: $screen-m){
        display: none;
    }
}