@import "Themes/constants.scss";
@import "../classes.module.scss";

.root {
	margin: auto;
	margin-top: 32px;
	overflow-x: auto;
	border-radius: 6px;
	box-shadow: 0px 4px 6px -1px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.1);
	@media screen and (max-width: 1400px) {
		box-shadow: 0px 4px 6px -1px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.1),
			inset -16px 1px 11px -11px rgba(16, 24, 40, 0.55);
	}
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	max-width: fit-content;

	@media (max-width: $screen-m) {
		max-width: unset;
	}
}

.root table {
	border-collapse: collapse;
	background: var(--color-window);
	border-radius: 6px;
	table-layout: fixed;
}

.table-header {
	color: var(--color-paragraph);
}

.table-header th {
	padding: 12px 24px 12px 24px;
	white-space: nowrap;
	text-align: left;
	font-weight: 400;
	vertical-align: middle;
}

.table-content {
	color: var(--color-text);
}

.current-phase {
	background: var(--color-table-row);
}

.table-content td {
	padding: 24px;
	white-space: nowrap;
	text-align: left;
}

.table-content-phase {
	font-weight: 700;
	font-size: 16px;
	color: var(--color-primary);
}

