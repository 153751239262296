.grid-card-mobile {
	display: flex;
	flex-direction: column;
	align-items: center;

    &:after {
        content: "";
		border-top: 1px solid var(--color-secondary);
        display: block;
        width: 100%;
        margin-top: 16px;
	}

	&:last-of-type{
		&:after{
			border-top: none;
			margin: 0;
		}
	}
}

.grid-card-title {
	color: var(--color-text);
	font-family: "Source Sans Pro";
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;

    margin-top: 16px;
}

.grid-card-content {
	color: var(--color-paragraph);
	font-family: "Source Sans Pro";
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
}
