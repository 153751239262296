@import "Themes/constants.scss";
@import "../classes.module.scss";

.root {
	display: flex;
	flex-direction: column;
	align-items: stretch;
}

.buttons-phases-container {
	display: flex;
	justify-content: center;
	gap: 0;
	button {
		border-radius: 0;
		border-right: none;
		border-left: none;
	}
	button:nth-child(1) {
		border-radius: 50px 0 0 50px;
		&[variant="ghost"] {
			border-left: 1px solid;
		}
	}
	button:nth-last-child(1) {
		border-radius: 0px 50px 50px 0px;
		&[variant="ghost"] {
			border-right: 1px solid;
		}
	}
}

.phase-container {
	display: flex;
	justify-content: center;
}

.phase {
	max-width: $screen-xs;
	display: flex;
	flex-direction: column;
	background-color: var(--color-window);
	box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.2);
	border-radius: 11px;
	padding: 8px 34px 24px 34px;
	margin-top: 24px;
	flex: 1;
}

.fundraising-type {
	display: flex;
	align-items: center;
	gap: 10px;
}

.crumbs-container {
	margin-top: 24px;
	display: flex;
	justify-content: center;
	gap: 16px;
}

