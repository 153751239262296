.burger {
    cursor: pointer;
	path {
		stroke: var(--color-text);
        transform: rotate(0deg) translate(0,0);
        transition: transform 200ms ease-in-out, opacity 200ms ease-in-out;
	}

	&[data-state="cross"] {
        :nth-child(1){
            transform: translate3d(-12px, 10px, 0px) rotate(-45deg);
        }
		:nth-child(2) {
            opacity: 0;
		}
        
        :nth-child(3){
            transform: translate3d(11px, -1px, 0px) rotate(45deg) ;
        }
		
	}
}
