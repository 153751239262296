@import "Themes/constants.scss";

.root {
	background: var(--color-window);
	/* Dark Mode/Shadow (SM) */

	box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.2);
	border-radius: 11px;

	padding: 32px;
	flex: 1;
}

.title {
	color: var(--color-primary);
	font-size: 20px;
	margin: 0;

	&:after {
		content: "";
		height: 1px;
		background-color: var(--color-secondary);
		margin-top: 12px;
		margin-bottom: 4px;
		display: block;
	}
}

.line {
	display: flex;
	justify-content: space-between;
	margin-bottom: 17px;

	&[data-background="true"] {
		background-color: var(--color-background);
		padding: 12px 16px;
		border-radius: 3px;
	}
}

.line-left {
	color: var(--color-text);
	font-size: 16px;
	font-weight: 600;
	line-height: 24px;
}

.line-right {
	color: var(--color-paragraph);
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
}

.button-container {
	display: flex;
	justify-content: center;
	margin-left: 8px;

	> button {
		width: 160px;
		height: 48px;
		padding: 12px 16px;
	}

	@media screen and (max-width: $screen-m) {
		margin-left: 0;
		margin-top: 8px;

		> button {
			width: 100%;
		}
	}
}

.box-container {
	display: flex;
	padding-top: 16px;

	@media screen and (max-width: $screen-m) {
		flex-direction: column;
	}

}

.lines-container {
	flex: 1;
	padding-right: 16px;
	@media screen and (max-width: $screen-m) {
		
	}
}

.actions-container {
	flex: 1;
	padding-left: 16px;
	border-left: 1px solid var(--color-input-placeholder);

	@media screen and (max-width: $screen-m) {
		padding-left: 0;
		padding-top: 16px;
		border-left: none;
		border-top: 1px solid var(--color-input-placeholder);
	}
}

.background {
	
	background-color: var(--color-background);
	padding: 12px 16px;
	border-radius: 3px;

	@media screen and (min-width: $screen-m) {
		width: 100%;
	}
}

.action {
	display: flex;
	padding-top: 8.5px;
	padding-bottom: 8.5px;

	&[data-background="true"] {
		background-color: var(--color-background);
		padding: 12px 16px;
		border-radius: 3px;
	}

	@media screen and (max-width: $screen-m) {
		flex-direction: column;
	}
}

