.root {
	background-color: var(--color-background);
	box-shadow: inset 0px 4px 8px rgba(0, 0, 0, 0.08);
	height: 10px;
	border-radius: 5px;
	overflow: hidden;
}

[theme-mode="dark"] .root {
	box-shadow: inset 0px 4px 8px rgba(0, 0, 0, 0.3);
}

.progress {
	border-radius: 5px;
	display: block;
	width: 100%;
	height: 100%;
	transition: width 300ms;
	background-image: var(--linear-gradient-progress-bar);

	&[data-percent="100"] {
		background-image: var(--linear-gradient-progress-bar-completed);
	}
}

