@import "Themes/constants.scss";

.root {
	height: 80px;
	position: sticky;
	top: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 16px;
	background-color: var(--color-background-transparency);
	backdrop-filter: blur(15px);
	z-index: 5;
	@media (max-width: $screen-m) {
		transition: transform 500ms ease-out;
		&[data-open="1"] {
			transform: translateY(0);
		}
	}
}

[top-menu-status="closed"] {
	@media (max-width: $screen-m) {
		.root {
			&[data-open="-1"] {
				transform: translateY(-100px);
			}
		}
	}
}

.content {
	flex-grow: 1;
	max-width: var(--content-max-width);
	display: flex;
	height: 36px;
}

.segments {
	flex: 1;
	display: flex;
	padding: 0 24px;
	flex-direction: row;
}

.segment {
	flex: 1;
	display: flex;
	justify-content: space-between;
	gap: 25px;
}

.burger {
	@media (min-width: $screen-m) {
		display: none;
	}
}

.segment-left {
	flex-grow: 1;
}

.segment-middle {
	flex-grow: 0;
}

.segment-right {
	flex-grow: 1;
	justify-content: right;
	@media (max-width: $screen-m) {
		display: none;
	}
}

.routes {
	display: flex;
	justify-content: center;
}

.route {
	text-decoration: none;

	font-family: var(--font-primary);
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	color: var(--color-text);

	white-space: nowrap;
	margin-top: auto;
	margin-bottom: auto;
	margin-right: 15px;
}

.active-route{
	color: var(--color-primary);
}

.logo img {
	width: 157.6px;
}

