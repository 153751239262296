@import "Themes/constants.scss";

.root {
	&.show {
		.tooltip {
			transform: translateY(0);
		}
		.shadow {
			opacity: 1;
		}
	}
}

.shadow {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 10;
	opacity: 0;
	pointer-events: none;
	transition: opacity 350ms $custom-easing;
}

.tooltip {
	z-index: 15;
	width: 100%;
	background: var(--color-window);
	border-radius: 11px 11px 0px 0px;
	position: fixed;
	bottom: 0;
	left: 0;
	transition: transform 400ms $custom-easing;
	text-align: left;
	filter: drop-shadow(0px 9px 18px rgba(0, 0, 0, 0.15));
	transform: translateY(100%);
	pointer-events: none;
}

.root-content {
	padding: 16px 24px;
}

.title {
	font-family: var(--font-primary);
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	line-height: 22px;
	color: var(--color-text);
}

.carrot {
	position: absolute;
	right: 20px;
	fill: var(--color-window);
}
