@import "../Base/classes.module.scss";
@import "Themes/constants.scss";

.progressbars {
	display: flex;
	margin-top: 48px;
	margin-bottom: 64px;
}

.progressbar-container {
	display: flex;
	flex-direction: column;
    flex: 1;
    padding: 0 40px 0 40px;

	@media screen and (max-width: $screen-s) {
		padding: 0 12px 0 12px;
	}
}

.progressbar {
	max-width: 373px;
}

.progressbar-title {
	font-family: var(font-primary);
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	color: var(--color-paragraph);
	text-transform: uppercase;
	margin-bottom: 4px;
    align-self: center;
    white-space: nowrap;
}

.button-kyc {
	display: flex;
	justify-content: center;
	margin-top: 62px;
}

